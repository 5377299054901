/*
  Flag images used are 32px PNG from https://www.iconfinder.com/iconsets/ensign-11
  Generated by http://css.spritegen.com CSS Sprite Generator
  https://en.wikipedia.org/wiki/List_of_official_languages_by_country_and_territory
*/

[class*='zef-flag-'],
[class*='zef-lang-'] {
  display: inline-block;
  overflow: hidden;
  width: 32px;
  height: 32px;

  text-align: left;
  background: url('../assets/images/flags-32px.png') no-repeat;
  background-position: 100% 100%;
  text-indent: -9999px;
}

figure[class*='zef-flag-'],
figure[class*='zef-lang-'] {
  margin: 0;
}

.zef-flag-small {
  transform: scale(0.75);
}

.zef-lang-other {
  background: url('../assets/images/other-language.png') no-repeat;
  background-size: 100%;
  background-position: center;
}

.zef-flag-ck {
  /* Cook Islands */
  background-position: -4px 0;
}

.zef-flag-bn {
  /* Brunei */
  background-position: -40px 0;
}

.zef-flag-bw,
.zef-lang-tn {
  /* Botswana, Tswana (Setswana) */
  background-position: -76px 0;
}

.zef-flag-ba,
.zef-lang-bs {
  /* Bosnia and Herzegovina (Босна и Херцеговина), Bosnian (Bosanski) */
  background-position: -112px 0;
}

.zef-flag-bo,
.zef-lang-ay {
  /* Bolivia, Aymara (Aymar) */
  background-position: -148px 0;
}

.zef-flag-bd,
.zef-lang-bn {
  /* Bangladesh (বাংলাদেশ), Bengali (বাংলা) */
  background-position: -220px 0;
}

.zef-flag-pm {
  /* Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon) */
  background-position: -256px 0;
}

.zef-flag-lt,
.zef-lang-lt {
  /* Lithuania (Lietuva), Lithuanian (Lietuvių) */
  background-position: -292px 0;
}

.zef-flag-id,
.zef-lang-id {
  /* Indonesia, Indonesian (Bahasa Indonesia) */
  background-position: -400px 0;
}

.zef-flag-as,
.zef-lang-sm {
  /* American Samoa, Samoan (Gagana Samoa) */
  background-position: -472px 0;
}

.zef-flag-gi {
  /* Gibraltar */
  background-position: -508px 0;
}

.zef-flag-gr,
.zef-lang-el {
  /* Greece (Ελλάδα), Greek (Ελληνικά) */
  background-position: -544px 0;
}

.zef-flag-gl,
.zef-lang-kl {
  /* Greenland (Kalaallit Nunaat), Greenlandic (Kalaallisut) */
  background-position: -4px -36px;
}

.zef-flag-gd {
  /* Grenada */
  background-position: -40px -36px;
}

.zef-flag-gu {
  /* Guam */
  background-position: -76px -36px;
}

.zef-flag-gt {
  /* Guatemala */
  background-position: -112px -36px;
}

.zef-flag-gg {
  /* Guernsey */
  background-position: -148px -36px;
}

.zef-flag-gw {
  /* Guinea-Bissau (Guiné Bissau) */
  background-position: -184px -36px;
}

.zef-flag-gn {
  /* Guinea (Guinée) */
  background-position: -220px -36px;
}

.zef-flag-gy {
  /* Guyana */
  background-position: -256px -36px;
}

.zef-flag-ad,
.zef-lang-ca {
  /* Andorra, Catalan (Català) */
  background-position: -292px -36px;
}

.zef-flag-ht,
.zef-lang-ht {
  /* Haiti, Haitian [Haitian Creole] (Krèyol ayisyen) */
  background-position: -328px -36px;
}

.zef-flag-hn {
  /* Honduras */
  background-position: -364px -36px;
}

.zef-flag-hk {
  /* Hong Kong (香港) */
  background-position: -400px -36px;
}

.zef-flag-hu,
.zef-lang-hu {
  /* Hungary (Magyarország), Hungarian (Magyar) */
  background-position: -436px -36px;
}

.zef-flag-is,
.zef-lang-is {
  /* Iceland (Ísland), Icelandic (Íslenska) */
  background-position: -472px -36px;
}

.zef-flag-in,
.zef-lang-hi {
  /* India (भारत), Hindi (हिन्दी) */
  background-position: -508px -36px;
}

.zef-flag-ir,
.zef-lang-fa {
  /* Iran (‫ایران‬‎), Persian (فارسی) */
  background-position: -544px -36px;
}

.zef-flag-ao {
  /* Angola */
  background-position: -4px -72px;
}

.zef-flag-iq {
  /* Iraq (‫العراق‬‎) */
  background-position: -40px -72px;
}

.zef-flag-ie,
.zef-lang-ga {
  /* Ireland, Irish (Gaeilge) */
  background-position: -76px -72px;
}

.zef-flag-im,
.zef-lang-gv {
  /* Isle of Man, Manx (Gaelg) */
  background-position: -112px -72px;
}

.zef-flag-il,
.zef-lang-he {
  /* Israel (‫ישראל‬‎), Hebrew (עברית) */
  background-position: -148px -72px;
}

.zef-flag-it,
.zef-lang-it {
  /* Italy (Italia), Italian (Italiano) */
  background-position: -184px -72px;
}

.zef-flag-jm {
  /* Jamaica */
  background-position: -220px -72px;
}

.zef-flag-jp,
.zef-lang-ja {
  /* Japan (日本), Japanese (日本語) */
  background-position: -256px -72px;
}

.zef-flag-je {
  /* Jersey */
  background-position: -292px -72px;
}

.zef-flag-jo {
  /* Jordan (‫الأردن‬‎) */
  background-position: -328px -72px;
}

.zef-flag-ai {
  /* Anguilla */
  background-position: -364px -72px;
}

.zef-flag-kz,
.zef-lang-kk {
  /* Kazakhstan (Казахстан), Kazakh (Қазақша) */
  background-position: -436px -72px;
}

.zef-flag-ke {
  /* Kenya */
  background-position: -472px -72px;
}

.zef-flag-ki {
  /* Kiribati */
  background-position: -508px -72px;
}

.zef-flag-kp {
  /* North Korea (조선 민주주의 인민 공화국) */
  background-position: -4px -108px;
}

.zef-flag-kr,
.zef-lang-ko {
  /* South Korea (대한민국), Korean (한국어) */
  background-position: -40px -108px;
}

.zef-flag-xk {
  /* Kosovo */
  background-position: -76px -108px;
}

.zef-flag-kw {
  /* Kuwait (‫الكويت‬‎) */
  background-position: -112px -108px;
}

.zef-flag-kg,
.zef-lang-ky {
  /* Kyrgyzstan (Кыргызстан), Kirghiz [Kyrgyz] (Kırgızca / Кыргызча) */
  background-position: -148px -108px;
}

.zef-flag-la,
.zef-lang-lo {
  /* Laos (ລາວ), Laotian [Lao] (ລາວ / Pha xa lao) */
  background-position: -184px -108px;
}

.zef-flag-lv,
.zef-lang-lv {
  /* Latvia (Latvija), Latvian (Latviešu) */
  background-position: -220px -108px;
}

.zef-flag-lb {
  /* Lebanon (‫لبنان‬‎) */
  background-position: -256px -108px;
}

.zef-flag-ls,
.zef-lang-st {
  /* Lesotho, Southern Sotho (Sesotho) */
  background-position: -292px -108px;
}

.zef-flag-ag {
  /* Antigua and Barbuda */
  background-position: -328px -108px;
}

.zef-flag-lr {
  /* Liberia */
  background-position: -364px -108px;
}

.zef-flag-ly {
  /* Libya (‫ليبيا‬‎) */
  background-position: -400px -108px;
}

.zef-flag-li {
  /* Liechtenstein */
  background-position: -436px -108px;
}

.zef-flag-lu,
.zef-lang-lb {
  /* Luxembourg, Luxembourgish (Lëtzebuergesch) */
  background-position: -472px -108px;
}

.zef-flag-mo {
  /* Macau (澳門) */
  background-position: -508px -108px;
}

.zef-flag-mk,
.zef-lang-mk {
  /* Macedonia (FYROM) (Македонија), Macedonian (Македонски) */
  background-position: -544px -108px;
}

.zef-flag-mg,
.zef-lang-mg {
  /* Madagascar (Madagasikara), Malagasy (Malagasy) */
  background-position: -4px -144px;
}

.zef-flag-mw,
.zef-lang-ny {
  /* Malawi, Chichewa (Chi-Chewa) */
  background-position: -40px -144px;
}

.zef-flag-ar {
  /* Argentina */
  background-position: -76px -144px;
}

.zef-flag-my,
.zef-lang-ms {
  /* Malaysia, Malay (Bahasa Melayu) */
  background-position: -112px -144px;
}

.zef-flag-mv,
.zef-lang-dv {
  /* Maldives, Divehi [Dhivehi] (ދިވެހިބަސް) */
  background-position: -148px -144px;
}

.zef-flag-ml {
  /* Mali */
  background-position: -184px -144px;
}

.zef-flag-mt,
.zef-lang-mt {
  /* Malta, Maltese (bil-Malti) */
  background-position: -220px -144px;
}

.zef-flag-mh,
.zef-lang-mh {
  /* Marshall Islands, Marshallese (Kajin Majel / Ebon) */
  background-position: -256px -144px;
}

.zef-flag-mr {
  /* Mauritania (‫موريتانيا‬‎) */
  background-position: -292px -144px;
}

.zef-flag-mu {
  /* Mauritius (Moris) */
  background-position: -328px -144px;
}

.zef-flag-mx {
  /* Mexico (México) */
  background-position: -364px -144px;
}

.zef-flag-fm {
  /* Micronesia */
  background-position: -400px -144px;
}

.zef-flag-am,
.zef-lang-hy {
  /* Armenia (Հայաստան), Armenian (Հայերեն) */
  background-position: -436px -144px;
}

.zef-flag-md {
  /* Moldova (Republica Moldova) */
  background-position: -472px -144px;
}

.zef-flag-mc {
  /* Monaco */
  background-position: -508px -144px;
}

.zef-flag-me {
  /* Montenegro (Crna Gora) */
  background-position: -544px -144px;
}

.zef-flag-ms {
  /* Montserrat */
  background-position: -4px -180px;
}

.zef-flag-ma {
  /* Morocco (‫المغرب‬‎) */
  background-position: -40px -180px;
}

.zef-flag-mz {
  /* Mozambique (Moçambique) */
  background-position: -76px -180px;
}

.zef-flag-mm,
.zef-lang-my {
  /* Myanmar (Burma) (မြန်မာ), Burmese (မြန်မာစာ) */
  background-position: -112px -180px;
}

.zef-flag-aw {
  /* Aruba */
  background-position: -184px -180px;
}

.zef-flag-na,
.zef-lang-af {
  /* Namibia (Namibië), Afrikaans (Afrikaans) */
  background-position: -220px -180px;
}

.zef-flag-nr,
.zef-lang-na {
  /* Nauru, Nauruan (Dorerin Naoero) */
  background-position: -256px -180px;
}

.zef-flag-np,
.zef-lang-ne {
  /* Nepal (नेपाल), Nepali (नेपाली) */
  background-position: -292px -180px;
}

.zef-flag-bq {
  /* Caribbean Netherlands */
  background-position: -328px -180px;
}

.zef-flag-nz,
.zef-lang-mi {
  /* New Zealand, Maori (Māori) */
  background-position: -364px -180px;
}

.zef-flag-ni {
  /* Nicaragua */
  background-position: -400px -180px;
}

.zef-flag-ne,
.zef-lang-ff {
  /* Niger (Nijar), Peul (Fulfulde) */
  background-position: -436px -180px;
}

.zef-flag-ng {
  /* Nigeria */
  background-position: -472px -180px;
}

.zef-flag-nu {
  /* Niue */
  background-position: -508px -180px;
}

.zef-flag-au {
  /* Australia */
  background-position: -544px -180px;
}

.zef-flag-nf {
  /* Norfolk Island */
  background-position: -4px -216px;
}

.zef-flag-mp {
  /* Northern Mariana Islands */
  background-position: -40px -216px;
}

.zef-flag-no,
.zef-lang-no {
  /* Norway (Norge), Norwegian (Norsk) */
  background-position: -76px -216px;
}

.zef-flag-om {
  /* Oman (‫عُمان‬‎) */
  background-position: -112px -216px;
}

.zef-flag-pk,
.zef-lang-ur {
  /* Pakistan (‫پاکستان‬‎), Urdu (اردو) */
  background-position: -148px -216px;
}

.zef-flag-pw {
  /* Palau */
  background-position: -184px -216px;
}

.zef-flag-ps {
  /* Palestine (‫فلسطين‬‎) */
  background-position: -220px -216px;
}

.zef-flag-pa {
  /* Panama (Panamá) */
  background-position: -256px -216px;
}

.zef-flag-pg {
  /* Papua New Guinea */
  background-position: -292px -216px;
}

.zef-flag-at {
  /* Austria (Österreich) */
  background-position: -328px -216px;
}

.zef-flag-py,
.zef-lang-gn {
  /* Paraguay, Guarani (Avañe'ẽ) */
  background-position: -364px -216px;
}

.zef-flag-pe {
  /* Peru (Perú) */
  background-position: -400px -216px;
}

.zef-flag-ph {
  /* Philippines */
  background-position: -436px -216px;
}

.zef-flag-pn {
  /* Pitcairn Islands */
  background-position: -472px -216px;
}

.zef-flag-pl,
.zef-lang-pl {
  /* Poland (Polska), Polish (Polski) */
  background-position: -508px -216px;
}

.zef-flag-pt,
.zef-lang-pt {
  /* Portugal, Portuguese (Português) */
  background-position: -544px -216px;
}

.zef-flag-pr {
  /* Puerto Rico */
  background-position: -4px -252px;
}

.zef-flag-qa {
  /* Qatar (‫قطر‬‎) */
  background-position: -40px -252px;
}

.zef-flag-ro,
.zef-lang-ro {
  /* Romania (România), Romanian (Română) */
  background-position: -76px -252px;
}

.zef-flag-az,
.zef-lang-az {
  /* Azerbaijan (Azərbaycan), Azerbaijani (Azərbaycanca / آذربايجان) */
  background-position: -112px -252px;
}

.zef-flag-ru,
.zef-lang-ru {
  /* Russia (Россия), Russian (Русский) */
  background-position: -148px -252px;
}

.zef-flag-rw,
.zef-lang-rw {
  /* Rwanda, Rwandi (Kinyarwandi) */
  background-position: -184px -252px;
}

.zef-flag-bl {
  /* Saint Barthélemy */
  background-position: -220px -252px;
}

.zef-flag-sh {
  /* Saint Helena */
  background-position: -256px -252px;
}

.zef-flag-kn {
  /* Saint Kitts and Nevis */
  background-position: -292px -252px;
}

.zef-flag-lc {
  /* Saint Lucia */
  background-position: -328px -252px;
}

.zef-flag-vc {
  /* Saint Vincent and the Grenadines */
  background-position: -364px -252px;
}

.zef-flag-bs {
  /* Bahamas */
  background-position: -400px -252px;
}

.zef-flag-ws {
  /* Samoa */
  background-position: -436px -252px;
}

.zef-flag-sm {
  /* San Marino */
  background-position: -472px -252px;
}

.zef-flag-st {
  /* São Tomé and Príncipe (São Tomé e Príncipe) */
  background-position: -508px -252px;
}

.zef-flag-sa,
.zef-lang-ar {
  /* Saudi Arabia (‫المملكة العربية السعودية‬‎), Arabic (العربية) */
  background-position: -544px -252px;
}

.zef-flag-sn {
  /* Senegal (Sénégal) */
  background-position: -4px -288px;
}

.zef-flag-rs,
.zef-lang-sr {
  /* Serbia (Србија), Serbian (Српски) */
  background-position: -40px -288px;
}

.zef-flag-sc {
  /* Seychelles */
  background-position: -76px -288px;
}

.zef-flag-bh {
  /* Bahrain (‫البحرين‬‎) */
  background-position: -112px -288px;
}

.zef-flag-sl {
  /* Sierra Leone */
  background-position: -148px -288px;
}

.zef-flag-sg,
.zef-lang-ta {
  /* Singapore, Tamil (தமிழ்) */
  background-position: -184px -288px;
}

.zef-flag-sk,
.zef-lang-sk {
  /* Slovakia (Slovensko), Slovak (Slovenčina) */
  background-position: -220px -288px;
}

.zef-flag-si,
.zef-lang-sl {
  /* Slovenia (Slovenija), Slovenian (Slovenščina) */
  background-position: -256px -288px;
}

.zef-flag-sb {
  /* Solomon Islands */
  background-position: -292px -288px;
}

.zef-flag-so,
.zef-lang-so {
  /* Somalia (Soomaaliya), Somalia [Somali] Soomaaliga */
  background-position: -328px -288px;
}

.zef-flag-za,
.zef-lang-zu {
  /* South Africa, Zulu (isiZulu) */
  background-position: -400px -288px;
}

.zef-flag-es,
.zef-lang-es {
  /* Spain (España), Spanish (Español) */
  background-position: -472px -288px;
}

.zef-flag-lk,
.zef-lang-si {
  /* Sri Lanka (ශ්‍රී ලංකාව), Sinhalese (සිංහල) */
  background-position: -508px -288px;
}

.zef-flag-sd {
  /* Sudan (‫السودان‬‎) */
  background-position: -544px -288px;
}

.zef-flag-sr {
  /* Suriname */
  background-position: -4px -324px;
}

.zef-flag-sz,
.zef-lang-ss {
  /* Swaziland (Eswatini), Swati (SiSwati) */
  background-position: -40px -324px;
}

.zef-flag-se,
.zef-lang-sv {
  /* Sweden (Sverige), Swedish (Svenska) */
  background-position: -76px -324px;
}

.zef-flag-ch {
  /* Switzerland (Schweiz) */
  background-position: -112px -324px;
}

.zef-flag-sy,
.zef-lang-ku {
  /* Syria (‫سوريا‬‎), Kurdish (Kurdî / كوردی) */
  background-position: -148px -324px;
}

.zef-flag-bb {
  /* Barbados */
  background-position: -184px -324px;
}

.zef-flag-tw {
  /* Taiwan (台灣) */
  background-position: -220px -324px;
}

.zef-flag-tj,
.zef-lang-tg {
  /* Tajikistan, Tajik (Тоҷикӣ) */
  background-position: -256px -324px;
}

.zef-flag-tz {
  /* Tanzania */
  background-position: -292px -324px;
}

.zef-flag-th,
.zef-lang-th {
  /* Thailand (ไทย), Thai (ไทย / Phasa Thai) */
  background-position: -328px -324px;
}

.zef-flag-tg {
  /* Togo */
  background-position: -364px -324px;
}

.zef-flag-to,
.zef-lang-to {
  /* Tonga, Tonga (Lea Faka-Tonga) */
  background-position: -400px -324px;
}

.zef-flag-tt {
  /* Trinidad and Tobago */
  background-position: -436px -324px;
}

.zef-flag-tn {
  /* Tunisia (‫تونس‬‎) */
  background-position: -472px -324px;
}

.zef-flag-tr,
.zef-lang-tr {
  /* Turkey (Türkiye), Turkish (Türkçe) */
  background-position: -508px -324px;
}

.zef-flag-tm,
.zef-lang-tk {
  /* Turkmenistan, Turkmen (Туркмен / تركمن) */
  background-position: -544px -324px;
}

.zef-flag-tc {
  /* Turks and Caicos Islands */
  background-position: -4px -360px;
}

.zef-flag-tv {
  /* Tuvalu */
  background-position: -76px -360px;
}

.zef-flag-by,
.zef-lang-be {
  /* Belarus (Беларусь), Belarusian (Беларуская) */
  background-position: -148px -360px;
}

.zef-flag-ug {
  /* Uganda */
  background-position: -184px -360px;
}

.zef-flag-ua,
.zef-lang-uk {
  /* Ukraine (Україна), Ukrainian (Українська) */
  background-position: -220px -360px;
}

.zef-flag-ae {
  /* United Arab Emirates (‫الإمارات العربية المتحدة‬‎) */
  background-position: -256px -360px;
}

.zef-flag-gb,
.zef-lang-en {
  /* United Kingdom */
  background-position: -292px -360px;
}

.zef-flag-us,
.zef-lang-us {
  /* United States */
  background-position: -328px -360px;
}

.zef-flag-uy {
  /* Uruguay */
  background-position: -364px -360px;
}

.zef-flag-uz,
.zef-lang-uz {
  /* Uzbekistan (Oʻzbekiston), Uzbek (Ўзбек) */
  background-position: -400px -360px;
}

.zef-flag-vu,
.zef-lang-bi {
  /* Vanuatu, Bislama (Bislama) */
  background-position: -436px -360px;
}

.zef-flag-va {
  /* Vatican City (Città del Vaticano) */
  background-position: -472px -360px;
}

.zef-flag-ve {
  /* Venezuela */
  background-position: -508px -360px;
}

.zef-flag-be {
  /* Belgium (België) */
  background-position: -544px -360px;
}

.zef-flag-vn,
.zef-lang-vi {
  /* Vietnam (Việt Nam), Vietnamese (Tiếng Việt) */
  background-position: -4px -396px;
}

.zef-flag-vg {
  /* British Virgin Islands */
  background-position: -40px -396px;
}

.zef-flag-vi {
  /* U.S. Virgin Islands */
  background-position: -76px -396px;
}

.zef-lang-cy {
  /* United Kingdom - Wales, Welsh (Cymraeg) */
  background-position: -112px -396px;
}

.zef-flag-eh {
  /* Western Sahara (‫الصحراء الغربية‬‎) */
  background-position: -148px -396px;
}

.zef-flag-ye {
  /* Yemen (‫اليمن‬‎) */
  background-position: -184px -396px;
}

.zef-flag-zm {
  /* Zambia */
  background-position: -220px -396px;
}

.zef-flag-zw,
.zef-lang-nd {
  /* Zimbabwe, North Ndebele (Sindebele) */
  background-position: -256px -396px;
}

.zef-flag-bz {
  /* Belize */
  background-position: -292px -396px;
}

.zef-flag-af {
  /* Afghanistan (‫افغانستان‬‎) */
  background-position: -328px -396px;
}

.zef-flag-bj {
  /* Benin (Bénin) */
  background-position: -364px -396px;
}

.zef-flag-bm {
  /* Bermuda */
  background-position: -400px -396px;
}

.zef-flag-bt,
.zef-lang-dz {
  /* Bhutan (འབྲུག), Dzongkha (ཇོང་ཁ) */
  background-position: -436px -396px;
}

.zef-flag-br {
  /* Brazil (Brasil) */
  background-position: -472px -396px;
}

.zef-flag-io {
  /* British Indian Ocean Territory */
  background-position: -544px -396px;
}

.zef-flag-bg,
.zef-lang-bg {
  /* Bulgaria (България), Bulgarian (Български) */
  background-position: -40px -432px;
}

.zef-flag-bf {
  /* Burkina Faso */
  background-position: -76px -432px;
}

.zef-flag-bi,
.zef-lang-rn {
  /* Burundi (Uburundi), Kirundi (Kirundi) */
  background-position: -112px -432px;
}

.zef-flag-kh,
.zef-lang-km {
  /* Cambodia (កម្ពុជា), Cambodian [Khmer] (ភាសាខ្មែរ) */
  background-position: -148px -432px;
}

.zef-flag-cm {
  /* Cameroon (Cameroun) */
  background-position: -184px -432px;
}

.zef-flag-ca {
  /* Canada */
  background-position: -220px -432px;
}

.zef-flag-ax {
  /* Åland Islands */
  background-position: -256px -432px;
}

.zef-flag-cv {
  /* Cape Verde (Kabu Verdi) */
  background-position: -292px -432px;
}

.zef-flag-ky {
  /* Cayman Islands */
  background-position: -328px -432px;
}

.zef-flag-cf,
.zef-lang-ln {
  /* Central African Republic (République centrafricaine), Lingala (Lingála) */
  background-position: -364px -432px;
}

.zef-flag-td {
  /* Chad (Tchad) */
  background-position: -400px -432px;
}

.zef-flag-cl {
  /* Chile */
  background-position: -436px -432px;
}

.zef-flag-cn,
.zef-lang-zh {
  /* China (中国), Chinese [Mandarin] (中文) */
  background-position: -472px -432px;
}

.zef-flag-al,
.zef-lang-sq {
  /* Albania (Shqipëri), Albanian (Shqip) */
  background-position: -508px -432px;
}

.zef-flag-co {
  /* Colombia */
  background-position: -544px -432px;
}

.zef-flag-km {
  /* Comoros (‫جزر القمر‬‎) */
  background-position: -4px -468px;
}

.zef-flag-cd,
.zef-lang-lu {
  /* Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo), Luba-Kasai (Tshiluba) */
  background-position: -40px -468px;
}

.zef-flag-cg,
.zef-lang-kg {
  /* Congo (Republic) (Congo-Brazzaville), Kongo (KiKongo) */
  background-position: -76px -468px;
}

.zef-flag-cr {
  /* Costa Rica */
  background-position: -112px -468px;
}

.zef-flag-ci {
  /* Côte d’Ivoire */
  background-position: -148px -468px;
}

.zef-flag-hr,
.zef-lang-hr {
  /* Croatia (Hrvatska), Croatian (Hrvatski) */
  background-position: -220px -468px;
}

.zef-flag-cu {
  /* Cuba */
  background-position: -256px -468px;
}

.zef-flag-cy {
  /* Cyprus (Κύπρος) */
  background-position: -292px -468px;
}

.zef-flag-cz,
.zef-lang-cs {
  /* Czech Republic (Česká republika), Czech (Česky) */
  background-position: -328px -468px;
}

.zef-flag-dk,
.zef-lang-da {
  /* Denmark (Danmark), Danish (Dansk) */
  background-position: -364px -468px;
}

.zef-flag-dj {
  /* Djibouti */
  background-position: -400px -468px;
}

.zef-flag-dm {
  /* Dominica */
  background-position: -436px -468px;
}

.zef-flag-do {
  /* Dominican Republic (República Dominicana) */
  background-position: -472px -468px;
}

.zef-flag-tl {
  /* Timor-Leste */
  background-position: -508px -468px;
}

.zef-flag-ec,
.zef-lang-qu {
  /* Ecuador, Quechua (Runa Simi) */
  background-position: -544px -468px;
}

.zef-flag-dz {
  /* Algeria (‫الجزائر‬‎) */
  background-position: -4px -504px;
}

.zef-flag-eg {
  /* Egypt (‫مصر‬‎) */
  background-position: -40px -504px;
}

.zef-flag-fk {
  /* Falkland Islands (Islas Malvinas) */
  background-position: -76px -504px;
}

.zef-flag-eu {
  /* European Union */
  background-position: -112px -504px;
}

.zef-flag-et,
.zef-lang-am {
  /* Ethiopia, Amharic (አማርኛ) */
  background-position: -148px -504px;
}

.zef-flag-ee,
.zef-lang-et {
  /* Estonia (Eesti), Estonian (Eesti) */
  background-position: -184px -504px;
}

.zef-flag-er,
.zef-lang-ti {
  /* Eritrea, Tigrinya (ትግርኛ) */
  background-position: -220px -504px;
}

.zef-flag-gq {
  /* Equatorial Guinea (Guinea Ecuatorial) */
  background-position: -256px -504px;
}

.zef-flag-sv {
  /* El Salvador */
  background-position: -328px -504px;
}

.zef-flag-fo,
.zef-lang-fo {
  /* Faroe Islands (Føroyar), Faroese (Føroyskt) */
  background-position: -364px -504px;
}

.zef-flag-fj,
.zef-lang-fj {
  /* Fiji, Fijian (Na Vosa Vakaviti) */
  background-position: -400px -504px;
}

.zef-flag-fi,
.zef-lang-fi {
  /* Finland (Suomi) */
  background-position: -436px -504px;
}

.zef-flag-fr,
.zef-lang-fr {
  /* France, French (Français) */
  background-position: -472px -504px;
}

.zef-flag-pf {
  /* French Polynesia (Polynésie française) */
  background-position: -508px -504px;
}

.zef-flag-ga {
  /* Gabon */
  background-position: -544px -504px;
}

.zef-flag-gm {
  /* Gambia */
  background-position: -4px -540px;
}

.zef-flag-ge,
.zef-lang-ka {
  /* Georgia (საქართველო), Georgian (ქართული) */
  background-position: -40px -540px;
}

.zef-flag-gh {
  /* Ghana (Gaana) */
  background-position: -76px -540px;
}

.zef-flag-mn,
.zef-lang-mn {
  /* Mongolia (Монгол), Mongolian (Монгол) */
  background-position: -184px -540px;
}

.zef-flag-nl,
.zef-lang-nl {
  /* Netherlands (Nederland), Dutch (Nederlands) */
  background-position: -148px -540px;
}

.zef-flag-de,
.zef-lang-de {
  /* Germany (Deutschland), German (Deutsch) */
  background-position: -112px -540px;
}

/*
.flag-2634287-bashkortostan-ensign-flag-nation {
  Russia - Republic of Bashkortostan
  background-position: -184px -0px;
}
.flag-2634291-ensign-flag-khakassia-nation {
  Russia - Republic of Khakassia
  background-position: -328px -0px;
}
.flag-2634292-ensign-flag-karakalpakstan-nation {
  Uzbekistan - Republic of Karakalpakstan
  background-position: -364px -0px;
}
.flag-2634294-abkhazia-ensign-flag-nation {
  Georgia - Republic of Abkhazia
  background-position: -436px -0px;
}
.flag-2634325-ensign-flag-karachay-nation {
  background-position: -400px -72px;
}
.flag-2634329-antarctica-ensign-flag-nation {
  Antarctica
  background-position: -544px -72px;
}
.flag-2634366-ensign-flag-nagorno-nation {
  Azerbaijan - Nagorno-Karabakh Republic
  background-position: -148px -180px;
}
.flag-2634420-ensign-flag-nation-somaliland {
  Somalia - Republic of Somaliland
  background-position: -364px -288px;
}
.flag-2634422-ensign-flag-nation-ossetia {
  Russia - Ossetia
  background-position: -436px -288px;
}
.flag-2634443-ensign-flag-nation-tuva {
  Russia - Tuva Republic
  background-position: -40px -360px;
}
.flag-2634445-ensign-flag-nation-udmurtia {
  Russia - Udmurt Republic
  background-position: -112px -360px;
}
.flag-2634472-antarctic-british-ensign-flag-nation-territory {
  British Antarctic Territory
  background-position: -508px -396px;
}
.flag-2634474-brittany-ensign-flag-nation {
  France - Brittany
  background-position: -4px -432px;
}
.flag-2634495-crimea-ensign-flag-nation {
  Russia - Republic of Crimea
  background-position: -184px -468px;
}
.flag-2634514-england-ensign-flag-nation {
  United Kingdom - England
  background-position: -292px -504px;
}
*/
