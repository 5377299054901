/* You can add global styles to this file, and also import other style files */

@use 'sass:meta';

@include meta.load-css('flex-grid');

@include meta.load-css('./assets/flags');
@include meta.load-css('./assets/icons/style-free');

@include meta.load-css('@angular/cdk/overlay-prebuilt');

@import 'tailwindcss/utilities';

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
}

input,
textarea {
  box-sizing: border-box;

  outline: none;

  resize: none;
  font-family: inherit;
  color: inherit;
}

*:-moz-placeholder {
  font-weight: lighter;
}

*::-moz-placeholder {
  font-weight: lighter;
}

*:-ms-input-placeholder {
  font-weight: lighter;
}

*::-webkit-input-placeholder {
  font-weight: lighter;
}
